<template>
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.1651 16.812C19.8882 17.4575 19.5461 18.073 19.1441 18.649C18.6071 19.416 18.1661 19.946 17.8281 20.241C17.3031 20.723 16.7391 20.971 16.1361 20.985C15.7041 20.985 15.1821 20.862 14.5741 20.612C13.9641 20.363 13.4041 20.241 12.8911 20.241C12.3541 20.241 11.7781 20.363 11.1611 20.612C10.5451 20.862 10.0471 20.993 9.66614 21.005C9.08914 21.03 8.51214 20.776 7.93714 20.241C7.57014 19.921 7.11114 19.371 6.56014 18.593C5.97014 17.764 5.48514 16.799 5.10514 15.702C4.69814 14.515 4.49414 13.367 4.49414 12.255C4.49414 10.982 4.76914 9.883 5.32014 8.963C5.73689 8.24047 6.33269 7.63743 7.05014 7.212C7.75761 6.78767 8.56523 6.55919 9.39014 6.55C9.85014 6.55 10.4531 6.692 11.2001 6.972C11.9471 7.252 12.4271 7.394 12.6361 7.394C12.7941 7.394 13.3251 7.227 14.2291 6.896C15.0821 6.589 15.8021 6.462 16.3921 6.512C17.9921 6.641 19.1931 7.271 19.9921 8.407C18.5621 9.274 17.8551 10.487 17.8691 12.044C17.8811 13.257 18.3221 14.266 19.1861 15.067C19.5681 15.4325 20.0138 15.725 20.5011 15.93C20.3951 16.237 20.2831 16.53 20.1651 16.812ZM16.4981 2.381C16.4981 3.331 16.1501 4.219 15.4591 5.04C14.6231 6.016 13.6131 6.581 12.5181 6.492C12.5038 6.37254 12.4968 6.25232 12.4971 6.132C12.4971 5.219 12.8931 4.243 13.6001 3.444C13.9521 3.04 14.4001 2.703 14.9431 2.435C15.4851 2.171 15.9971 2.025 16.4791 2C16.4921 2.128 16.4981 2.255 16.4981 2.381Z"
      fill="white"
      fill-opacity="0.87"
    />
  </svg>
</template>
